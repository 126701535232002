import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"



const ApplicationPage = () => {
  //javascript
  const title = 'Case Studies';
  const metatitle = 'Case Studies';
  const description = 'Meta Description for The Case Studies';
  const metadescription = description;
  return(
    <Layout bodyclass="application" title={title}>
      <SEO
        title={metatitle}
        description={metadescription}
      />
      <p>Under Development</p>
      
    </Layout>
  )
}

export default ApplicationPage
